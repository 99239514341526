<template>
  <span ref="contentRef"> </span>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
const props = defineProps({
  content: {
    type: String,
    required: true,
  },
});
const data = ref("");
const contentRef = ref(null);
data.value = props.content;

watch(
  () => props.content,
  (newValue) => {
    data.value = newValue; // 更新数据
    if (window.MathJax) {
      renderMathContent(data.value); // 渲染新内容
      window.MathJax.typesetPromise(); // 重新渲染公式
    }
  }
);
// 数据：单条数据的字符串形式
// 使用 window.MathJax 渲染数学公式
onMounted(() => {
  if (window.MathJax) {
    // MathJax 初始化
    window.MathJax.startup.promise
      .then(() => {
        // 渲染内容
        renderMathContent(data.value);
        // 重新渲染公式
        window.MathJax.typesetPromise();
      })
      .catch((err) => {
        console.error("MathJax 初始化出错", err);
      });
  } else {
    console.error("MathJax 未加载");
  }
});

// 渲染内容到页面
const renderMathContent = (content) => {
  if (/\$.*?\$/.test(content)) {
    // 如果内容中包含数学公式，进行替换
    const processedContent = content.replace(/<br\s*\/?>/gi, '').replace(/\$(.*?)\$/g, "\\($1\\)");
    if (contentRef.value) {
      contentRef.value.innerHTML = `<p style="white-space: pre-wrap;">${processedContent}</p>`;
    }
  } else {
    if (contentRef.value) {
      contentRef.value.innerHTML = `<p style="white-space: pre-wrap;">${content.replace(/<br\s*\/?>/gi, '')}</p>`;
    }
  }
};
</script>
