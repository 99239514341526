<template>
  <div
    :class="['course_card', `course_card_${courseInfo?.courseState}`]"
    ref="cardRef"
    @click="clickBtn(0)"
  >
    <div class="card_top">
      <slot name="cardBg">
        <img class="card_bg" src="@/assets/bg.png" />
      </slot>
      <div
        class="right_top_tag"
        v-if="[1, 2, 3].includes(courseInfo?.courseState)"
      >
        <span><slot name="tag">占位标签</slot></span>
      </div>
      <div class="top_desc">
        <template v-if="isShowTitle">
          <p class="title">
            <slot name="title">占位标题</slot>
          </p>
          <p class="tips">
            <slot name="subTitle">占位二级标题</slot>
          </p>
        </template>

        <div class="course_count" v-if="isShowCount">
          <slot name="topDesc">占位描述</slot>
        </div>
      </div>
    </div>

    <div class="card_bottom">
      <div class="end_time">
        <template v-if="!isShowSchedule">
          <slot name="middle">占位中间描述</slot>
        </template>
        <template v-else>
          <slot name="middleHover">占位悬浮</slot>
        </template>
      </div>

      <div class="schedule">
        <slot name="bottom">
          <div class="home" v-if="[3, 4].includes(courseInfo?.courseState)">
            <!-- <span class="unit">￥</span>
            <span class="curr_price">{{ courseInfo?.sellingPrice }}</span>
            <span class="prev_price">￥{{ courseInfo?.originalPrice }}</span> -->
          </div>
          <div class="study" v-else>
            <span class="study_schedule"
              >学习进度 {{ courseInfo?.studySchedule }}</span
            >
            <span class="study_mastery"
              >知识点掌握 {{ courseInfo?.mastery }}%</span
            >
          </div>
          <a-button class="btn" @click.stop="clickBtn(1)">
            <!-- <img
              src="@/assets/classification/buy.svg"
              class="buy_icon"
              v-if="courseInfo?.courseState == 4"
            /> -->
             <img
              src="@/assets/classification/user.png"
              class="buy_icon"
          
              v-if="courseInfo?.courseState == 4"
            />
            {{ btnTextMap[courseInfo?.courseState] }}
          </a-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from '@/store';
import { ref, reactive, getCurrentInstance, onMounted } from 'vue';

const { proxy } = getCurrentInstance(),
  userStore = useUserStore();

const props = defineProps({
  courseInfo: Object,
  type: String,
  isShowTitle: { type: Boolean, default: false },
  isShowCount: { type: Boolean, default: true },
});

const cardRef = ref();

const isShowSchedule = ref(false);

const btnTextMap = {
  0: '继续学习',
  1: '继续学习',
  2: '复习巩固',
  3: '重新购买',
  // 4: '购买课程',
  4: '申请开通',
};

onMounted(() => {
  const dom = cardRef.value;
  dom.addEventListener('mouseover', function () {
    if (![3, 4].includes(props.courseInfo.courseState) && userStore.token)
      isShowSchedule.value = true;
  });
  dom.addEventListener('mouseout', function () {
    if (![3, 4].includes(props.courseInfo.courseState) && userStore.token)
      isShowSchedule.value = false;
  });
});

const emits = defineEmits(['clickBtn']);
const clickBtn = (e) => {
  emits('clickBtn', { type: e, data: props.courseInfo });
};
</script>

<style lang="less" scoped>
.course_card {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.65);

  .card_top {
    width: 100%;
    height: 202px;
    position: relative;
    border-radius: 16px 16px 0 0;
    overflow: hidden;

    .card_bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .right_top_tag {
      width: 125px;
      height: 125px;
      position: absolute;
      top: -62px;
      right: -62px;
      transform: rotate(45deg);
      display: flex;
      align-items: flex-end;
      justify-content: center;

      span {
        display: block;
        text-align: center;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 600;
        line-height: 26px;
      }
    }

    .top_desc {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 25px 24px 12px 24px;
      box-sizing: border-box;

      .title {
        height: fit-content;
        font-size: 28px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.9);
        line-height: 36px;
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        text-stroke: 2px rgba(0, 0, 0, 0.15);
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 2px rgba(0, 0, 0, 0.15);
      }

      .tips {
        height: fit-content;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.9);
        line-height: 26px;
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        text-stroke: 2px rgba(0, 0, 0, 0.15);
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 2px rgba(0, 0, 0, 0.15);
        margin-top: 8px;
      }

      .course_count {
        width: 100%;
        height: fit-content;
        height: 52px;
        background: rgba(0, 0, 0, 0.4);
        padding: 12px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }

  .card_bottom {
    width: 100%;
    height: 104px;
    padding: 17px 17px 16px 16px;
    box-sizing: border-box;
    border-radius: 0 0 16px 16px;
    overflow: hidden;

    .end_time {
      width: 100%;
      height: 22px;
      display: flex;
      align-items: center;
    }

    .schedule {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 17px;

      .home {
        .unit {
          height: 24px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: #e34d59;
          line-height: 24px;
        }

        .curr_price {
          height: 32px;
          font-size: 24px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: #e34d59;
          line-height: 32px;
          margin-left: 4px;
        }

        .prev_price {
          width: 55px;
          height: 24px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          line-height: 24px;
          text-decoration: line-through;
          margin-left: 4px;
        }
      }

      .study {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;

        .study_schedule,
        .study_mastery {
          height: 22px;
          font-size: 13px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          line-height: 22px;

          &.study_mastery {
            margin-left: 15px;
          }
        }
      }

      .btn {
        width: 88px;
        height: 32px;
        border-radius: 16px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        color: rgba(255, 255, 255, 0.9);
        line-height: 22px;
        border: unset;
        padding: 5px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        // &:hover {
        //   box-shadow: 0 4px 10px 0 rgba(43, 164, 113, 0.85);
        // }
      }
    }
  }

  &.course_card_2 {
    .card_top {
      .right_top_tag {
        background: #c6f3d7;

        span {
          color: #008858;
        }
      }
    }

    .card_bottom {
      .schedule {
        .study {
          .study_schedule,
          .study_mastery {
            color: #2ba471;
          }
        }

        .btn {
          background: #2ba471;
          border: 1px solid #2ba471;
        }
      }
    }
  }

  &.course_card_0,
  &.course_card_1 {
    .card_top {
      position: relative;

      .right_top_tag {
        background: #fff1e9;

        span {
          color: #e37318;
        }
      }
    }

    .card_bottom {
      .schedule {
        .study {
          .study_schedule,
          .study_mastery {
            color: #e37318;
          }
        }

        .btn {
          background: #e37318;
          color: rgba(255, 255, 255, 0.9);
          transition: all ease;

          &:hover {
            background: linear-gradient(270deg, #e37318 0%, #fa9550 100%);
            box-shadow: 0 4px 10px 0 rgba(250, 149, 80, 0.85);
            border: 1px solid #e37318;
          }
        }
      }
    }
  }

  &.course_card_3 {
    .card_top {
      position: relative;

      .right_top_tag {
        background: #f7fcf1;

        span {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .card_bottom {
      .schedule {
        .study {
          .study_schedule,
          .study_mastery {
            color: #d54941;
          }
        }

        .btn {
          background: linear-gradient(270deg, #d54941 0%, #f6685d 100%);

          &:hover {
            box-shadow: 0 4px 10px 0 rgba(246, 104, 93, 0.85);
          }
        }
      }
    }
  }

  &.course_card_4 {
    .card_bottom {
      .schedule {
        .btn {
          width: fit-content;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: #d54941;
          line-height: 28px;
          background: unset;
          box-shadow: unset;

          .buy_icon {
            width: 26px;
            height: 26px;
            margin-right: 8px;
          }

          &:hover {
            background: linear-gradient(270deg, #d54941 0%, #f6685d 100%);
            box-shadow: 0 4px 10px 0 rgba(246, 104, 93, 0.85);
            border-radius: 16px 16px 16px 16px;
            border: 1px solid #d54941;
            color: rgba(255, 255, 255, 0.9);
            font-size: 14px;

            .buy_icon {
              width: 16px;
              height: 16px;
              // content: url('@/assets/classification/buy_hover.svg');
              content: url('@/assets/classification/user_hover.png');
            }
          }
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1),
      0 8px 10px 1px rgba(0, 0, 0, 0.06), 0 3px 14px 2px rgba(0, 0, 0, 0.05);

    .card_top {
      border-left: 4px solid #fff;
      border-top: 4px solid #fff;
      border-right: 4px solid #fff;
    }
  }
}
</style>
