<template>
  <pre v-html="highlightedCode"></pre>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // 引入主题样式
import "prismjs/components/prism-c"; // 引入 C++ 语法高亮
import "prismjs/components/prism-python"; // 引入 Python 语法高亮

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
  language: {
    type: String,
    required: true,
  },
});
const highlightedCode = ref("");
const updateHighlightedCode = () => {
  highlightedCode.value = Prism.highlight(props.code, Prism.languages[props.language], props.language);
};

// 在组件挂载时初始化
onMounted(updateHighlightedCode);

// 监听 code 和 language 的变化
watch(() => props.code, updateHighlightedCode);
watch(() => props.language, updateHighlightedCode);
</script>
