import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 获取客观题题目
  getObjInfoByPaperId(data) {
    return request({
      url:
        "/practice/tbl-paper-info/getObjInfoByPaperId?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取编译题题目
  getProgramInfoByPaperId(data) {
    return request({
      url:
        "/practice/tbl-program-info/getProgramInfoByPaperId?" +
        parameterChange(data),
      method: "post",
      data,
    });
  },

  // 获取操作题题目
  getOperateInfoByPaperId(data) {
    return request({
      url:
        "/practice/tbl-operate-info/getOperateInfoByPaperId?" +
        parameterChange(data),
      method: "post",
      data,
    });
  },

  // 保存试卷做题记录（非提交）
  savePaperRecords(data) {
    return request({
      url: "/practice/tbl-paper-info/savePaperRecords",
      method: "post",
      data,
    });
  },

  // 获取试卷做题记录
  getPaperRecords(data) {
    return request({
      url: "/practice/tbl-paper-info/getPaperRecords?" + parameterChange(data),
      method: "post",
      data,
    });
  },
  // 获取答题卡
  getAnswerCard(data) {
    return request({
      url: "/practice/tbl-exam-result/getAnswerCard?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 提交全部客观题
  judgeAnswer(query, data) {
    return request({
      url: "/practice/tbl-obj-answer/judgeAnswer?" + parameterChange(query),
      method: "post",
      data,
    });
  },

  // 运行c++/python代码
  runProgramCode(data) {
    return request({
      url: "/practice/tbl-program-answer/runProgramCode",
      method: "post",
      data,
    });
  },

  // 自由练习c++python代码
  runProgramCodeFree(data) {
    return request({
      url: "/practice/tbl-program-answer/runProgramCodeFree",
      method: "post",
      data,
    });
  },

  // 提交编译题
  submitProgramCode(data) {
    return request({
      url: "/practice/tbl-program-answer/submitProgramCode",
      method: "post",
      data,
    });
  },

  // 提交操作题
  submitScratchAnswer(data) {
    return request({
      url: "/practice/tbl-operate-answer/submitScratchAnswer",
      method: "post",
      data,
    });
  },

  // 更新试卷自动提交状态
  updateAutoSubmit(data) {
    return request({
      url: "/practice/tbl-exam-result/updateAutoSubmit",
      method: "post",
      data,
    });
  },

  // 创建考试统计结果
  createExamResult(data) {
    return request({
      url: "/practice/tbl-exam-result/createExamResult",
      method: "post",
      data,
    });
  },

  // 获取统计结果
  getExamResult(data) {
    return request({
      url: "/practice/tbl-exam-result/getExamResult?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 个人中心--获取错题
  getWrongQuestionList(data) {
    return request({
      url:
        "/practice/tbl-paper-info/getWrongQuestionList?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },

  // 删除错题
  deleteWrongQuestion(data) {
    return request({
      url: "/practice/tbl-paper-info/deleteWrongQuestion",
      method: "post",
      data,
    });
  },

  // 获取筛选试卷
  getPageListWithAllCondition(data) {
    return request({
      url:
        "/practice/tbl-paper-info/getPageListWithAllCondition?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取练习题库--刷题统计的信息
  getQuizzingStatistics(data) {
    return request({
      url:
        "/practice/tbl-student-paper/getQuizzingStatistics?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取试卷关键信息
  getPaperWaitingMsg(data) {
    return request({
      url:
        "/practice/tbl-paper-info/getPaperWaitingMsg?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取学生paper的状态
  getPaperStatus(data) {
    return request({
      url: "/practice/tbl-paper-info/getPaperStatus?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取系统当前时间
  getSystemTime() {
    return request({
      url: "/user/stuInfo/getSystemTime",
      method: "get",
    });
  },

  // 保存代码
  saveProgramCode(data) {
    return request({
      url: "/practice/tbl-program-answer/saveProgramCode",
      method: "post",
      data,
    });
  },

  // 获取代码
  getProgramCode(data) {
    return request({
      url: "/practice/tbl-program-answer/getProgramCode",
      method: "post",
      data,
    });
  },
  // 保存scratch文件
  saveScratchAnswer(data) {
    return request({
      url: "/practice/tbl-operate-answer/saveScratchAnswer",
      method: "post",
      data,
    });
  },
  // 获取scratch文件
  getScratchAnswer(data) {
    return request({
      url: "/practice/tbl-operate-answer/getScratchAnswer",
      method: "post",
      data,
    });
  },
  // 开始作答
  startPaper(data) {
    return request({
      url: "/practice/tbl-paper-info/startPaper?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 练习试卷重做
  clearStuPaperState(data) {
    return request({
      url: "/practice/tbl-student-paper/clearStuPaperState",
      method: "post",
      data,
    });
  },
  // 获取赛事/等考列表
  getContestList(data) {
    return request({
      url: "/practice/tbl-contest-info/getContestList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取题库列表
  getPackList(data) {
    return request({
      url: "/practice/tbl-contest-info/getPackList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取试卷列表
  getPaperListByPackId(data) {
    return request({
      url:
        "/practice/tbl-contest-paper/getPaperListByPackId?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取题库详情
  getPackDetail(data) {
    return request({
      url: "/practice/tbl-contest-pack/getPackDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取题库知识点
  getKnowledgeByPackId(data) {
    return request({
      url:
        "/practice/tbl-contest-knowledge/getKnowledgeByPackId?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },
  // 绑定免费题库
  bindFreePack(data) {
    return request({
      url: "/practice/tbl-contest-pack/bindFreePack",
      method: "post",
      data,
    });
  },
  // 学生已购买题库刷题进度
  getUserBuyPackList(data) {
    return request({
      url:
        "/practice/tbl-contest-info/getUserBuyPackList?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取赛事/等考列表
  getContestList(data) {
    return request({
      url: "/practice/tbl-contest-info/getContestList?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 题库能力雷达图
  getPackAbilityChart(data) {
    return request({
      url:
        "/practice/tbl-contest-pack/getAbilityChart?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 题库知识点掌握度列表
  getPackKnowledgeMastery(data) {
    return request({
      url:
        "/practice/tbl-contest-pack/getKnowledgeMastery?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },

  // 题库知识点掌握度Top
  getPackMasteryTop(data) {
    return request({
      url: "/practice/tbl-contest-pack/getMasteryTop?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 题库整体掌握度
  getPackMastery(data) {
    return request({
      url: "/practice/tbl-contest-pack/getPackMastery?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 赛事/等考能力雷达图
  getInfoAbilityChart(data) {
    return request({
      url:
        "/practice/tbl-contest-info/getAbilityChart?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 赛事/等考知识点掌握度列表
  getInfoKnowledgeMastery(data) {
    return request({
      url:
        "/practice/tbl-contest-info/getKnowledgeMastery?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },

  // 赛事/等考题库刷题情况Top
  getContestInfoMasteryTop(data) {
    return request({
      url: "/practice/tbl-contest-info/getMasteryTop?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 赛事/等考整体掌握度
  getInfoContestMastery(data) {
    return request({
      url:
        "/practice/tbl-contest-info/getContestMastery?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 赛事/等考详情
  getContestDetail(data) {
    return request({
      url:
        "/practice/tbl-contest-info/getContestDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取赛事/等考的课程/题库
  getContestContentList(data) {
    return request({
      url:
        "/practice/tbl-contest-info/getContestContentList?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },
  // 选拔赛题库练习模式试卷
  getTrialsPaper(data) {
    return request({
      url: "/practice/tbl-contest-pack/getTrialsPaper?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 选拔赛题库测试模式试卷
  generateTrialsPaper(data) {
    return request({
      url: "/practice/tbl-contest-pack/generateTrialsPaper",
      method: "post",
      data,
    });
  },
  // 获取选拔赛练习模式作答卡
  getQuestionCard(data) {
    return request({
      url:
        "/practice/tbl-paper-question/getQuestionCard?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // AI刷题
  generatePracticePaper(data) {
    return request({
      url: "/practice/tbl-paper-info/generatePracticePaper",
      method: "post",
      data,
    });
  },
  // 获取省赛题库试卷
  generatePaper(data) {
    return request({
      url: "/practice/tbl-contest-pack/generatePaper",
      method: "post",
      data,
    });
  },
  // 绑定免费试卷
  bindFreePaper(data) {
    return request({
      url: "/practice/tbl-paper-info/bindFreePaper",
      method: "post",
      data,
    });
  },
};
